import { useEffect, useState } from "react";
import QRCode from "qrcode";
import { useParams } from "react-router-dom";
import { QRContent } from "./QRContent";

import styles from "./content.module.scss";

const Content = () => {
    const [content, setContent] = useState(null);
    const params = useParams();

    useEffect(() => {
        fetch(`${window.config.backendDomain}/voucher/${params.id}`)
            .then(x => x.json())
            .then(x => QRCode.toDataURL(window.location.href).then(y => setContent({ ...x, qrCode: y })));
    }, []);

    return (
        <div className={styles.content}>
            {
                content &&
                <QRContent {...content} />
            }
        </div>
    );
};

export {
    Content
};
