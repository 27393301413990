import { useState, useEffect } from "react";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Statistic } from "./Statistic";
import { Scanner } from "./Scanner";
import { Settings } from "./Settings";
import { Generation } from "./Generation";
import { Members } from "./Members";
import { MemberImport } from "./MemberImport";

import styles from "./admin.module.scss";

const Admin = () => {
    const [token, setToken] = useState(null);
    const navigate  = useNavigate();

    useEffect(() => {
        const bearer = cookies.get("token");

        setToken(bearer ?? null);

        if(bearer) return;

        navigate("/login");
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.generator}>
                <Statistic token={token}/>
                <Settings token={token}/>
                <Generation token={token}/>
                <MemberImport token={token}/>
                <Members token={token}/>
            </div>
            <Scanner token={token}/>
        </div>
    );
};

export {
    Admin
};
