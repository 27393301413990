import PropTypes from "prop-types";
import { Accordeon } from "./Accordeon";
import { useEffect, useState } from "react";
import Papa from "papaparse";

import styles from "./memberimport.module.scss";

const MemberImport = props => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState([]);
    const [uploaded, setUploaded] = useState(false);
    const [error, setError] = useState(null);

    const fileReader = new FileReader();
    const handleOnSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const file     = formData.get("csv");

        if(!file) return;

        fileReader.onloadend = x => {
            const parsed = Papa.parse(x.target.result, {
                header: true
            }).data.filter(y => y.Geburtsdatum);
            const mapped = parsed.map(y => ({
                email:     y["E-Mail"],
                birthday:  new Date(new Date(y.Geburtsdatum.split(".").reverse().join("-")).setUTCFullYear(2024)).toISOString(),
                firstname: y.Vorname,
                lastname:  y.Nachname
            }));

            setContent(mapped);
        };

        fileReader.readAsText(file);
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if(content.length === 0) return;

        fetch(`${window.config.backendDomain}/members`, {
            method:  "POST",
            headers: {
                "Content-Type":  "application/json",
                "Authorization": `Bearer ${props.token}`
            },
            body: JSON.stringify(content)
        }).then(x => {
            if(!x.ok) return setError(true);

            setError(false);
            return setUploaded(true);
        });
    }, [content]);

    return (
        <Accordeon text="Mitglieder importieren" isOpen={open} onOpen={toggleOpen} onClose={toggleOpen}>
            {
                error &&
                <div className={styles.error}>
                    <span>Upload fehlgeschlagen!</span>
                </div>
            }
            {
                uploaded &&
                <div className={styles.success}>
                    <span>Erfolgreich hochgeladen!</span>
                </div>
            }
            <form onSubmit={handleOnSubmit}>
                <div className={styles.header}>
                    <input type="file" accept=".csv" name="csv"/>
                    <div className={styles.buttonContainer}>
                        <button className={styles.button} type="submit">
                            <span>CSV Hochladen</span>
                        </button>
                    </div>
                </div>
            </form>
        </Accordeon>
    );
};

MemberImport.propTypes = {
    token: PropTypes.string
};

export {
    MemberImport
};
