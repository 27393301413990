import PropTypes from "prop-types";
import { Accordeon } from "./Accordeon";
import { useEffect, useState } from "react";

import styles from "./settings.module.scss";

const Settings = props => {
    const [open, setOpen] = useState(false);
    const [settings, setSettings] = useState(null);
    const [init, setInit] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const onSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const host     = formData.get("host");
        const port     = formData.get("port");
        const email    = formData.get("email");
        const username = formData.get("username");
        const password = formData.get("password");

        fetch(`${window.config.backendDomain}/settings`, {
            method:  "PUT",
            headers: {
                "Content-Type":  "application/json",
                "Authorization": `Bearer ${props.token}`
            },
            body: JSON.stringify({
                host,
                port,
                email,
                username,
                password
            })
        });
    };

    useEffect(() => {
        if(!props.token) return;

        fetch(`${window.config.backendDomain}/settings`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        }).then(x => x.json()).then(x => {
            setSettings(x);
            setInit(true);
        });
    }, [props.token]);

    return (
        <Accordeon text="Einstellungen" isOpen={open} onOpen={toggleOpen} onClose={toggleOpen}>
            {
                init &&
                <form onSubmit={onSubmit}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={styles.item}>
                                    <span>Email-Host:</span>
                                </td>
                                <td className={styles.item}>
                                    <input
                                        autoComplete="off"
                                        className={styles.input}
                                        type="text"
                                        name="host"
                                        defaultValue={settings?.host ?? ""}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.item}>
                                    <span>Email-Port:</span>
                                </td>
                                <td className={styles.item}>
                                    <input
                                        autoComplete="off"
                                        className={styles.input}
                                        type="number"
                                        name="port"
                                        defaultValue={settings?.port ?? ""}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.item}>
                                    <span>Email-Adresse:</span>
                                </td>
                                <td className={styles.item}>
                                    <input
                                        autoComplete="email"
                                        className={styles.input}
                                        type="text"
                                        name="email"
                                        defaultValue={settings?.email ?? ""}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.item}>
                                    <span>Email-Nutzer:</span>
                                </td>
                                <td className={styles.item}>
                                    <input
                                        autoComplete="off"
                                        className={styles.input}
                                        type="text"
                                        name="username"
                                        defaultValue={settings?.username ?? ""}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.item}>
                                    <span>Email-Passwort:</span>
                                </td>
                                <td className={styles.item}>
                                    <input
                                        autoComplete="off"
                                        className={styles.input}
                                        type="password"
                                        name="password"
                                        defaultValue={settings?.password ?? ""}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button className={styles.button} type="submit">
                        <span>Speichern</span>
                    </button>
                </form>
            }
        </Accordeon>
    );
};

Settings.propTypes = {
    token: PropTypes.string
};

export {
    Settings
};
