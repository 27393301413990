import PropTypes from "prop-types";

import styles from "./qrcontent.module.scss";

const QRContent = props => {
    const isValid    = props.amount > 0 && Date.now() < new Date(props.expiryDate).getTime();
    const expiryDate = Intl.DateTimeFormat("de-DE", {
        year:  "numeric",
        month: "long",
        day:   "numeric"
    }).format(new Date(props.expiryDate));


    return (
        <div className={styles.container}>
            {
                !props.isAdmin &&
                <div className={styles.headline}>
                    <h3>Geschenkgutschein*</h3>
                </div>
            }
            <img className={isValid ? styles.success : styles.failure} src={props.qrCode}/>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td className={styles.item}><span>Gültig bis:</span></td>
                        <td><span>{ expiryDate }</span></td>
                    </tr>
                    <tr>
                        <td className={styles.item}><span>Betrag:</span></td>
                        <td><span>{ props.amount } €</span></td>
                    </tr>
                </tbody>
            </table>
            {
                !props.isAdmin &&
                <span>* Einzulösen bei den Seniorenspielen</span>
            }
        </div>
    );
};

QRContent.propTypes = {
    isAdmin:    PropTypes.bool,
    qrCode:     PropTypes.string,
    expiryDate: PropTypes.string,
    amount:     PropTypes.number
};

QRContent.defaultProps = {
    isAdmin: false
};

export {
    QRContent
};
