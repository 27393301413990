import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./statistic.module.scss";

const Statistic = props => {
    const [statistic, setStatistic] = useState({});

    useEffect(() => {
        if(!props.token) return;

        fetch(`${window.config.backendDomain}/statistic`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        }).then(x => x.json()).then(x => setStatistic(x));
    }, [props.token]);

    return (
        <div className={styles.container}>
            <div className={styles.item}>
                <span className={styles.label}>Herausgegebene Coupons per Email</span>
                <span className={styles.content}>{ statistic.email ?? 0}</span>
            </div>
            <div className={styles.item}>
                <span className={styles.label}>Herausgegebene Coupons per Post</span>
                <span className={styles.content}>{ statistic.postal ?? 0}</span>
            </div>
            <div className={styles.item}>
                <span className={styles.label}>Geleerte Coupons</span>
                <span className={styles.content}>{ statistic.completed ?? 0}</span>
            </div>
        </div>
    );
};

Statistic.propTypes = {
    token: PropTypes.string
};

export {
    Statistic
};
