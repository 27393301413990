import { useLayoutEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Admin } from "./Admin";
import { Login } from "./Login";

import styles from "./root.module.scss";
import { Content } from "./Content";

const Root = () => {
    const [history]         = useState(createBrowserHistory());
    const [state, setState] = useState({
        action:   history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]); // eslint-disable-line

    return (
        <BrowserRouter
            location={state.location}
            navigationType={state.action}
            navigator={history}
        >
            <div className={styles.container}>
                <Routes>
                    <Route path="*" element={<Navigate to="/login"/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/admin/*" element={<Admin/>}/>
                    <Route path=":id" element={<Content/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    );
};

export {
    Root
};
