import PropTypes from "prop-types";
import { Accordeon } from "./Accordeon";
import { useEffect, useState } from "react";

import styles from "./members.module.scss";

const Members = props => { // eslint-disable-line complexity
    const [open, setOpen]       = useState(false);
    const [content, setContent] = useState(null);
    const [options, setOptions] = useState({
        page:     1,
        pageSize: 10,
        keyword:  null,
        orderKey: "birthday",
        order:    "asc"
    });

    const toggleOpen = () => {
        setOpen(!open);
    };

    const onSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const keyword = formData.get("keyword");

        setOptions({
            ...options,
            page: 1,
            keyword
        });
    };

    const onClick = size => {
        if(options.size === size) return;

        setOptions({ ...options, pageSize: size, page: 1 });
    };

    const onSort = key => {
        if(options.orderKey !== key) return setOptions({
            ...options,
            page:     1,
            orderKey: key,
            order:    "asc"
        });

        return setOptions({
            ...options,
            page:     1,
            orderKey: key,
            order:    options.order === "desc" ? "asc" : "desc"
        });
    };

    useEffect(() => {
        if(!props.token) return;

        const params = Object.fromEntries(Object.entries(options).filter(([_, a]) => a !== null));
        const query  = new URLSearchParams(params);

        fetch(`${window.config.backendDomain}/members?${query}`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        }).then(x => x.json()).then(x => {
            setContent(x);
        });
    }, [props.token, options]);

    return (
        <Accordeon text="Mitgliederliste" isOpen={open} onOpen={toggleOpen} onClose={toggleOpen}>
            {
                content &&
                <div className={styles.content}>
                    <form onSubmit={onSubmit}>
                        <input className={styles.input} type="text" name="keyword"/>
                        <button className={styles.button} type="submit">
                            <span>Suchen</span>
                        </button>
                    </form>
                    <div>
                        <table className={styles.table}>
                            <thead className={styles.header}>
                                <tr className={styles.row}>
                                    <th className={styles.head} onClick={() => onSort("firstname")}>
                                        <div className={styles.headItem}>
                                            <div className={styles.headline}>
                                                <span>Vorname</span>
                                            </div>
                                            {
                                                options.orderKey === "firstname" &&
                                                <div className={styles.order}>
                                                    <span className="material-symbols-outlined">{ options.order === "asc" ? "expand_less" : "expand_more" }</span>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                    <th className={styles.head} onClick={() => onSort("lastname")}>
                                        <div className={styles.headItem}>
                                            <div className={styles.headline}>
                                                <span>Nachname</span>
                                            </div>
                                            {
                                                options.orderKey === "lastname" &&
                                                <div className={styles.order}>
                                                    <span className="material-symbols-outlined">{ options.order === "asc" ? "expand_less" : "expand_more" }</span>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                    <th className={styles.head} onClick={() => onSort("email")}>
                                        <div className={styles.headItem}>
                                            <div className={styles.headline}>
                                                <span>Email</span>
                                            </div>
                                            {
                                                options.orderKey === "email" &&
                                                <div className={styles.order}>
                                                    <span className="material-symbols-outlined">{ options.order === "asc" ? "expand_less" : "expand_more" }</span>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                    <th className={styles.head} onClick={() => onSort("birthday")}>
                                        <div className={styles.headItem}>
                                            <div className={styles.headline}>
                                                <span>Geburtstag</span>
                                            </div>
                                            {
                                                options.orderKey === "birthday" &&
                                                <div className={styles.order}>
                                                    <span className="material-symbols-outlined">{ options.order === "asc" ? "expand_less" : "expand_more" }</span>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={styles.content}>
                                {
                                    content.map((x, idx) => {
                                        const day = new Date(x.birthday);

                                        return (
                                            <tr key={idx} className={styles.row}>
                                                <td className={styles.cell}>
                                                    <span>{ x.firstname }</span>
                                                </td>
                                                <td className={styles.cell}>
                                                    <span>{ x.lastname }</span>
                                                </td>
                                                <td className={styles.cell}>
                                                    <span>{ x.email }</span>
                                                </td>
                                                <td className={styles.cell}>
                                                    <span>{ `${day.getDate().toString().padStart(2, "0")}.${(day.getMonth() + 1).toString().padStart(2, "0")}` }</span>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.size}>
                            <span className={options.pageSize === 10 ? styles.selected : styles.clickable} onClick={() => onClick(10)}>10</span>
                            <span className={options.pageSize === 25 ? styles.selected : styles.clickable} onClick={() => onClick(25)}>25</span>
                            <span className={options.pageSize === 100 ? styles.selected : styles.clickable} onClick={() => onClick(100)}>100</span>
                        </div>
                        <div className={styles.page}>
                            {
                                options.page > 1 &&
                                <span className="material-symbols-outlined" onClick={() => setOptions({ ...options, page: options.page - 1 })}>chevron_left</span>
                            }
                            <span className={styles.pageNumber}>{ options.page }</span>
                            <span className="material-symbols-outlined" onClick={() => setOptions({ ...options, page: options.page + 1 })}>chevron_right</span>
                        </div>
                    </div>
                </div>
            }
        </Accordeon>
    );
};

Members.propTypes = {
    token: PropTypes.string
};

export {
    Members
};
