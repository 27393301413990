import { useEffect, useState } from "react";
// import QrReader from "react-qr-scanner";
import PropTypes from "prop-types";
import QRCode from "qrcode";
import { QRContent } from "./QRContent";
import { QrReader } from "react-qr-reader";

import styles from "./scanner.module.scss";

const Scanner = props => {
    const [scan, setScan]       = useState(null);
    const [content, setContent] = useState(null);

    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const amount   = formData.get("amount");

        await fetch(scan.replace(window.config.domain, `${window.config.backendDomain}/voucher`), {
            method:  "PUT",
            headers: {
                "Content-Type":  "application/json",
                "Authorization": `Bearer ${props.token}`
            },
            body: JSON.stringify({
                usedAt: new Date().toISOString(),
                amount: content.amount - amount
            })
        });

        setContent({
            ...content,
            usedAt: new Date().toISOString(),
            amount: content.amount - amount
        });
    };
    const onScan = (x, error) => {
        if(error) return console.log(error);
        if(!x)    return null;

        return setScan(x.text);
    };

    const onClick = () => {
        setScan(null);
        setContent(null);
    };

    useEffect(() => {
        if(!scan) return;

        fetch(scan.replace(window.config.domain, `${window.config.backendDomain}/voucher`))
            .then(x => x.json())
            .then(x => QRCode.toDataURL(scan).then(y => setContent({ ...x, qrCode: y })));
    }, [scan]);

    return (
        <div>
            {
                !scan && ("ontouchstart" in document.documentElement) &&
                <div>
                    <QrReader
                        style={{
                            width: "100%"
                        }}
                        constraints={{
                            facingMode: "rear"
                        }}
                        onResult={onScan}
                    />
                </div>
            }
            {
                content &&
                <div className={styles.content}>
                    <QRContent {...content} isAdmin={true}/>
                    <div className={styles.retry} onClick={onClick}>
                        <span>Neustart</span>
                    </div>
                    {
                        content.amount > 0 && Date.now() < new Date(content.expiryDate).getTime() &&
                        <form className={styles.form} onSubmit={onSubmit}>
                            <input
                                autoComplete="off"
                                className={styles.input}
                                type="number"
                                step="0.01"
                                min="0"
                                name="amount"
                            />
                            <button className={styles.button} type="submit">Betrag einlösen</button>
                        </form>
                    }
                </div>
            }
        </div>
    );
};

Scanner.propTypes = {
    token: PropTypes.string
};

export {
    Scanner
};
