import styles from "./login.module.scss";
import cookies from "js-cookie";
import md5 from "md5";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const onSubmit = async e => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const username = formData.get("username");
        const password = formData.get("password");

        if(username?.length === 0 || password?.length === 0) return;

        try {
            const response = await fetch(`${window.config.backendDomain}/login`, {
                method:  "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: username,
                    password: md5(password)
                })
            }).then(x => x.json());

            const stayLoggedIn = formData.get("save");
            const options = stayLoggedIn !== "on" ? {} : {
                expires: 7
            };

            cookies.set("username", username, options);
            cookies.set("token", response.token, options);

            navigate("/admin");
        } catch(err) {
            setError(true);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <img className={styles.logo} src="/skyline.png"/>
                <form className={styles.form} onSubmit={onSubmit}>
                    {
                        error &&
                        <span>Logindaten falsch, bitte versuchen Sie es erneut.</span>
                    }
                    <input
                        autoComplete="username"
                        placeholder="Benutzername"
                        className={styles.input}
                        name="username"
                        type="text"
                    />
                    <input
                        autoComplete="current-password"
                        placeholder="Passwort"
                        className={styles.input}
                        name="password"
                        type="password"
                    />
                    <div>
                        <div className={styles.stayLoggedIn}>
                            <input id="save" name="save" type="checkbox"/>
                            <label htmlFor="save">
                                <span>Eingeloggt bleiben?</span>
                            </label>
                        </div>
                        <button className={styles.button} type="submit">
                            <span>Einloggen</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export {
    Login
};
