import PropTypes from "prop-types";

import styles from "./accordeon.module.scss";

const Accordeon = props => {
    const toggle = e => {
        if(props.disabled) return null;
        if(props.isOpen)   return props.onClose(e);

        return props.onOpen(e);
    };

    return (
        <div className={[styles.accordeon, props.isOpen && styles.background].filter(x => x)}>
            <div className={styles.header} onClick={toggle}>
                <div className={styles.text}>
                    <span>{ props.text }</span>
                </div>
                {
                    props.isOpen &&
                    <span className="material-symbols-outlined">expand_less</span>
                }
                {
                    !props.isOpen &&
                    <span className="material-symbols-outlined">expand_more</span>
                }
            </div>
            {
                props.isOpen &&
                <div className={styles.content}>
                    {
                        props.children
                    }
                </div>
            }
        </div>
    );
};

Accordeon.propTypes = {
    isOpen:   PropTypes.bool,
    disabled: PropTypes.bool,
    text:     PropTypes.string,
    onClose:  PropTypes.func,
    onOpen:   PropTypes.func,
    children: PropTypes.node
};

Accordeon.defaultProps = {
    isOpen:  false,
    onClose: x => x,
    onOpen:  x => x
};

export { Accordeon };
