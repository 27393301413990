import { useState } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";

import styles from "./admin.module.scss";
import { Accordeon } from "./Accordeon";

const Generation = props => {
    const [_blob, setBlob]        = useState(null);
    const [open, setOpen]         = useState(false);
    const [fileName, setFileName] = useState(null);

    const onClick = async e => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const amount   = parseInt(formData.get("amount"), 10);

        const voucherCodes = await fetch(`${window.config.backendDomain}/voucher/postal`, {
            method:  "POST",
            headers: {
                "Content-Type":  "application/json",
                "Authorization": `Bearer ${props.token}`
            },
            body: JSON.stringify({
                amount
            })
        }).then(x => x.json());

        const csv  = Papa.unparse(voucherCodes.map(x => [`${window.config.domain}/${x.code}`]));
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url  = window.URL.createObjectURL(blob);
        const exportDate = new Intl.DateTimeFormat("de-DE", {
            year:   "numeric",
            month:  "numeric",
            day:    "numeric",
            hour:   "numeric",
            minute: "numeric",
            second: "numeric"
        }).format(new Date());

        setBlob(url);
        setFileName(`Code-Export-${exportDate.replace(", ", "-")}.csv`);
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <Accordeon text="Codegenerierung" onOpen={toggleOpen} onClose={toggleOpen} isOpen={open}>
            <div>
                <form className={styles.form} onSubmit={onClick}>
                    <div className={styles.line}>
                        <input
                            autoComplete="off"
                            className={styles.input}
                            type="number"
                            name="amount"
                            placeholder="Wie viele Codes möchtest du generieren?"
                        />
                        <button className={styles.button} type="submit">
                            <span>Generieren</span>
                        </button>
                        {
                            _blob &&
                            <a href={_blob} className={styles.button} download={fileName}>
                                <span>Download</span>
                            </a>
                        }
                    </div>
                </form>
            </div>
        </Accordeon>
    );
};

Generation.propTypes = {
    token: PropTypes.string
};

export {
    Generation
};
